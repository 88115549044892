const faqPage = {
    readyFn: function () {
        if ($('.categories-list').length > 0) {
            
            $('.categories-list button').on('click', function () {
                let thisCat = $(this).data('cat');
                
                $('.categories-list button').removeClass('active');
                $(this).addClass('active');

                if (thisCat != '') {
                    $('.questions-list .faq-item').hide();
    
                    $('.questions-list [data-cat="' + thisCat + '"]').show();
                } else {
                    $('.questions-list .faq-item').show();
                }
            });
        }

        $(".questions-list .question").on("click", function () {
            let $thisQuestion = $(this);
            let $thisAnswer = $(this).parent().find('.answer');
            let $thisBrick = $(this).parents('.questions-list');

            $thisBrick.find('.question').not($thisQuestion).removeClass('active');
            $thisBrick.find('.answer').not($thisAnswer).slideUp('fast');
            
            $(this).toggleClass('active');
            $thisAnswer.slideToggle();
        });
    }
}

export { faqPage };